import * as dataRequestService from "../shared/svc-data-request";
import * as $ from "jquery";

const dataService = dataRequestService.GetDataRequestService();

export function loginUser(userName, password): JQueryPromise<any> {
    const defer = $.Deferred();
    const data = { username: userName, password: password };
    dataService.SendJsonRequestWithData("POST",
        "/api/Login/Authenticate",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function retrieveToken(heldTokenKey) {
    const defer = $.Deferred();
    const data = { heldTokenKey: heldTokenKey };
    dataService.SendJsonRequestWithData("GET",
        "/api/Login/GetHeldToken",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function getAppVersion() {
    const defer = $.Deferred();
    dataService.SendJsonRequest("GET",
        "/api/Login/GetVersion",
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) }
    );
    return defer.promise();
}

export function validateMfaUniversal(code, state, heldTokenKey) {
    const defer = $.Deferred();
    const data = { code, state, heldTokenKey };
    dataService.SendJsonRequestWithDataVerbose("GET",
        "/api/Login/ValidateMFA",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true,
        true
    );
    return defer.promise();
}

export function sendPasswordResetLink(emailAddress: string, recaptchaResponse: string): JQueryPromise<any> {
    const defer = $.Deferred();
    const data = { emailAddress, recaptchaResponse };
    dataService.SendJsonRequestWithData("POST",
        "/api/Security/SendPasswordResetLink",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function validateResetPasswordRequest(emailAddress: string, dateToken: string, emailHash: string) {
    const defer = $.Deferred();
    const data = { emailAddress, dateToken, emailHash };
    dataService.SendJsonRequestWithData("GET",
        "/api/Security/ValidateResetPasswordRequest",
        data,
        (result) => { defer.resolve(result) },
        () => { defer.resolve(false) },
        true
    );
    return defer.promise();
}

export function resetPassword(newPassword: string, emailAddress: string, dateToken: string, emailHash: string, recaptchaResponse: string): JQueryPromise<any> {
    var defer = $.Deferred();
    var data = {
        newPassword, emailAddress, dateToken, emailHash, recaptchaResponse
    };
    dataService.SendJsonRequestWithData("POST",
        "/api/Security/ResetPassword",
        data,
        function (result) {
            defer.resolve(result);
        },
        function (result) {
            defer.resolve(false);
        }, true);
    return defer.promise();
}

export function GetUserRolesAndPermissions(appRefName: string) {
    const defer = $.Deferred();
    const data = { appRefName: appRefName };
    dataService.SendJsonRequestWithData("GET",
        "/api/Security/GetAppRolePermissions",
        data,
        (result) => { defer.resolve(result); },
        () => { defer.resolve(false); }, false);
    return defer.promise();
}

export function GetUserAdminInfo(): Promise<any> {
    var promise = new Promise((resolve, reject) => {
        const data = {};
        dataService.SendJsonRequestWithData("GET",
            "/api/UserAdmin/GetUserAdminInfo",
            data,
            (result) => { resolve(result) },
            () => { resolve(false) },
            true
        );
    });
    return promise;
}