import React, { useEffect, useState } from "react";

const ProtectedRoute = ({ permissionCheck, appRefName, appName, children }) => {
    const [hasPermission, setHasPermission] = useState(null);

    useEffect(() => {
        setHasPermission(null);

        const checkPermission = async () => {
            try {
                const result = await permissionCheck(appRefName);
                setHasPermission(result);
            } catch {
                setHasPermission(false);
            }
        };

        checkPermission();
    }, [permissionCheck, appRefName]);

    if (hasPermission === null) {
        return <div>Loading...</div>;
    }

    if (!hasPermission) {
        return <div style={{ margin: 20 }}>
            <h4>You do not have access to {appName}.</h4>
            <p>
                If you need access to this application, please submit a ticket via the&nbsp;
                <b>
                    <a href="https://surgerypartners.service-now.com/sp?id=sc_cat_item&sys_id=0bce7feddba5be4000025dd5ce96191a&sysparm_category=7bdf1ee4db6e9410df2f5dd5ce96191f" target="_new">ServiceNow Service Portal</a>.
                </b>
            </p>
        </div>;
    }

    return children;    
};

export default ProtectedRoute;